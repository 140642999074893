import { RowFlex } from '@ifca-ui/core'
import {
  CalendarMonthRounded,
  MonetizationOnRounded,
  NotificationsRounded,
} from '@mui/icons-material'
import { Typography } from '@mui/material'
import IconX from 'components/General/IconX'
import useAppData from 'components/General/useAppData'
import { formatDate, formatTime } from 'helpers/StringNumberFunction/formatDate'
import PBnumberCircle from './ProgressBilling/PBcomponent/PBnumberCircle'

const usePAdata = (getPAsummary, saleID, showPage) => {
  const { projectID } = useAppData()
  const path = `/${projectID}/purchaser-accounts`

  const submenuList = [
    {
      title: 'Progress Billing',
      tooltipTitle: 'Total Billed',
      secondTooltipTitle: 'Total Outstanding',
      amount: getPAsummary?.progressBillingSummary?.totalCharges,
      path: `${path}/${saleID}/progress-billing`,
      rightTextColor: 'primary',
      rightIcon: <MonetizationOnRounded />,
      rightIconColor: 'primary',
      description: showPage ? (
        <RowFlex gap={'4px'} className="pbNumber">
          {getPAsummary?.progressBillingSummary?.pbData?.map((data, index) => (
            <PBnumberCircle data={data} key={index} />
          ))}
        </RowFlex>
      ) : (
        <></>
      ),
      secondAmount: getPAsummary?.progressBillingSummary?.totalUnallocated,
      secondRightTextColor: 'red',
      secondRightIcon: <MonetizationOnRounded />,
      secondRightIconColor: 'red',
    },
    {
      title: 'Misc Billing',
      tooltipTitle: 'Total Billed',
      secondTooltipTitle: 'Total Outstanding',
      amount: showPage ? getPAsummary.miscBillingSummary?.totalCharges : 0,
      path: `${path}/${saleID}/misc-billing`,
      rightTextColor: 'primary',
      rightIcon: <MonetizationOnRounded />,
      rightIconColor: 'primary',
      description: (
        <Typography component="div" variant="inherit" className="text-desc">
          <span>
            <span className="search-bar-count-f">
              {showPage && getPAsummary.miscBillingSummary?.totalDraft}
            </span>
            &nbsp; Draft
          </span>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <span>
            <span className="search-bar-count-f">
              {showPage && getPAsummary.miscBillingSummary?.totalSubmitted}
            </span>
            &nbsp; Submitted
          </span>
        </Typography>
      ),
      secondAmount: showPage
        ? getPAsummary.miscBillingSummary?.totalUnallocated
        : 0,
      secondRightTextColor: 'red',
      secondRightIcon: <MonetizationOnRounded />,
      secondRightIconColor: 'red',
    },
    {
      title: 'Late Interest Charges',
      tooltipTitle: 'Total Charges',
      secondTooltipTitle: 'Total Outstanding',
      amount: showPage ? getPAsummary.lateInterestSummary?.totalCharges : 0,
      path: `${path}/${saleID}/late-interest-charges`,
      rightTextColor: 'primary',
      rightIcon: <MonetizationOnRounded />,
      rightIconColor: 'primary',
      secondAmount: showPage
        ? getPAsummary.lateInterestSummary?.totalUnallocated
        : 0,
      secondRightTextColor: 'red',
      secondRightIcon: <MonetizationOnRounded />,
      secondRightIconColor: 'red',
    },
    {
      title: 'Debit Note',
      tooltipTitle: 'Total Debited',
      secondTooltipTitle: 'Total Unallocated',
      amount: showPage ? getPAsummary.debitNoteSummary?.totalCharges : 0,
      path: `${path}/${saleID}/debit-note`,
      rightTextColor: 'primary',
      rightIcon: <MonetizationOnRounded />,
      rightIconColor: 'primary',
      description: (
        <Typography component="div" variant="inherit" className="text-desc">
          <span>
            <span className="search-bar-count-f">
              {showPage && getPAsummary.debitNoteSummary?.totalDraft}
            </span>
            &nbsp; Draft
          </span>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <span>
            <span className="search-bar-count-f">
              {showPage && getPAsummary.debitNoteSummary?.totalSubmitted}
            </span>
            &nbsp; Submitted
          </span>
        </Typography>
      ),
      secondAmount: showPage
        ? getPAsummary.debitNoteSummary?.totalUnallocated
        : 0,
      secondRightTextColor: 'red',
      secondRightIcon: <MonetizationOnRounded />,
      secondRightIconColor: 'red',
    },
    {
      title: 'Credit Note',
      tooltipTitle: 'Total Credited',
      secondTooltipTitle: 'Total Unallocated',
      amount: showPage ? getPAsummary.creditNoteSummary?.totalCharges : 0,
      path: `${path}/${saleID}/credit-note`,
      rightTextColor: 'primary',
      rightIcon: <MonetizationOnRounded />,
      rightIconColor: 'primary',
      description: (
        <Typography component="div" variant="inherit" className="text-desc">
          <span>
            <span className="search-bar-count-f">
              {showPage && getPAsummary.creditNoteSummary?.totalDraft}
            </span>
            &nbsp; Draft
          </span>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <span>
            <span className="search-bar-count-f">
              {showPage && getPAsummary.creditNoteSummary?.totalSubmitted}
            </span>
            &nbsp; Submitted
          </span>
        </Typography>
      ),
      secondAmount: showPage
        ? getPAsummary.creditNoteSummary?.totalUnallocated
        : 0,
      secondRightTextColor: 'red',
      secondRightIcon: <MonetizationOnRounded />,
      secondRightIconColor: 'red',
    },
    {
      title: 'Rebate',
      tooltipTitle: 'Total Rebate',
      secondTooltipTitle: 'Total Unallocated',
      amount: showPage ? getPAsummary.rebateSummary?.totalCharges : 0,
      path: `${path}/${saleID}/rebate`,
      rightTextColor: 'primary',
      rightIcon: <MonetizationOnRounded />,
      rightIconColor: 'primary',
      description: (
        <Typography component="div" variant="inherit" className="text-desc">
          <span>
            <span className="search-bar-count-f">
              {showPage && getPAsummary.rebateSummary?.totalDraft}
            </span>
            &nbsp; Draft
          </span>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <span>
            <span className="search-bar-count-f">
              {showPage && getPAsummary.rebateSummary?.totalSubmitted}
            </span>
            &nbsp; Submitted
          </span>
        </Typography>
      ),
      secondAmount: showPage ? getPAsummary.rebateSummary?.totalUnallocated : 0,
      secondRightTextColor: 'red',
      secondRightIcon: <MonetizationOnRounded />,
      secondRightIconColor: 'red',
    },
    {
      title: 'Contra Negative',
      tooltipTitle: 'Total Contra',
      secondTooltipTitle: 'Total Unallocated',
      amount: showPage ? Math.abs(getPAsummary.contraSummary?.totalCharges) : 0,
      path: `${path}/${saleID}/contra`,
      rightTextColor: 'primary',
      rightIcon: <MonetizationOnRounded />,
      rightIconColor: 'primary',
      description: (
        <Typography component="div" variant="inherit" className="text-desc">
          <span>
            <span className="search-bar-count-f">
              {showPage && getPAsummary.contraSummary?.totalDraft}
            </span>
            &nbsp; Draft
          </span>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <span>
            <span className="search-bar-count-f">
              {showPage && getPAsummary.contraSummary?.totalSubmitted}
            </span>
            &nbsp; Submitted
          </span>
        </Typography>
      ),
      secondAmount: showPage
        ? Math.abs(getPAsummary.contraSummary?.totalUnallocated)
        : 0,
      secondRightTextColor: 'red',
      secondRightIcon: <MonetizationOnRounded />,
      secondRightIconColor: 'red',
    },
    {
      title: 'Payment',
      tooltipTitle: 'Total Paid',
      secondTooltipTitle: 'Total Unallocated',
      amount: showPage ? getPAsummary.collectionSummary?.totalCharges : 0,
      path: `${path}/${saleID}/collection`,
      rightTextColor: 'primary',
      rightIcon: <MonetizationOnRounded />,
      rightIconColor: 'primary',
      description: (
        <Typography component="div" variant="inherit" className="text-desc">
          <span>
            <span className="search-bar-count-f">
              {showPage && getPAsummary.collectionSummary?.totalDraft}
            </span>
            &nbsp; Draft
          </span>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <span>
            <span className="search-bar-count-f">
              {showPage && getPAsummary.collectionSummary?.totalSubmitted}
            </span>
            &nbsp; Submitted
          </span>
        </Typography>
      ),
      secondAmount: showPage
        ? getPAsummary.collectionSummary?.totalUnallocated
        : 0,
      secondRightTextColor: 'red',
      secondRightIcon: <MonetizationOnRounded />,
      secondRightIconColor: 'red',
    },
    {
      title: 'Refund',
      tooltipTitle: 'Total Refunded',
      amount: showPage ? getPAsummary.refundSummary?.totalCharges : 0,
      path: `${path}/${saleID}/refund`,
      rightTextColor: 'primary',
      rightIcon: <MonetizationOnRounded />,
      rightIconColor: 'primary',
      description: (
        <Typography component="div" variant="inherit" className="text-desc">
          <span>
            <span className="search-bar-count-f">
              {showPage && getPAsummary.refundSummary?.totalDraft}
            </span>
            &nbsp; Draft
          </span>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <span>
            <span className="search-bar-count-f">
              {showPage && getPAsummary.refundSummary?.totalSubmitted}
            </span>
            &nbsp; Submitted
          </span>
        </Typography>
      ),
    },
    {
      title: 'Payment Reminder',
      path: `${path}/${saleID}/payment-reminder`,
      description: (
        <RowFlex gap={'10px'} className="text-desc">
          {[1, 2, 3].map(index => (
            <RowFlex key={index} gap={'4px'}>
              <div>{`${index}. `}</div>
              <RowFlex crossAxisAlignment="center" className="c-orange">
                {getPAsummary?.paymentReminder[`reminder${index}`] || 0}
                <IconX icon={NotificationsRounded} size={13} />
              </RowFlex>
            </RowFlex>
          ))}
        </RowFlex>
      ),
    },
    {
      title: 'Collection Activity',
      date:
        showPage && getPAsummary?.collectionActivity
          ? `${formatDate(
              showPage && getPAsummary.collectionActivity?.activityDate
            )}
            ,
            ${formatTime(
              showPage && getPAsummary.collectionActivity?.activityDate
            )}`
          : '',
      path: `${path}/${saleID}/collection-activity`,
      rightTextColor: 'red',
      rightIcon:
        showPage && getPAsummary?.collectionActivity ? (
          <CalendarMonthRounded />
        ) : (
          ''
        ),
      rightIconColor: 'common.black',
      description: (
        <Typography className="text-desc">
          {showPage && getPAsummary?.collectionActivity?.activityRemark}
        </Typography>
      ),
    },
  ]
  return { submenuList }
}

export default usePAdata
