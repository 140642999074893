import { Mic, Stop } from '@mui/icons-material'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition'

export const VoiceTextField = ({
  mounted,
  label,
  name,
  required,
  control,
  value,
  setValue,
  controllerProps,
  customOnchange,
  handleCustomOnChange,
  record,
  setRecord,
}: any) => {
  const [fieldName, setFieldName] = useState(null)

  const { transcript, listening, resetTranscript } = useSpeechRecognition()
  useEffect(() => {
    if (!!transcript || transcript !== '') {
      setValue(name, transcript)
    }
  }, [transcript])

  useEffect(() => {
    if (record) {
      resetTranscript()
      SpeechRecognition.startListening({ continuous: true })
    } else {
      SpeechRecognition.stopListening()
    }
  }, [record])

  if (mounted) {
    return (
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, onBlur, value: renderValue } }) => {
          useEffect(() => {
            console.log('value: ' + renderValue)
          }, [renderValue])

          return (
            <TextField
              margin="normal"
              id="standard-basic"
              variant="standard"
              label={label}
              name={name}
              {...controllerProps}
              fullWidth
              multiline
              required={required}
              value={renderValue || ''}
              onChange={customOnchange ? handleCustomOnChange : onChange}
              onBlur={onBlur}
              InputProps={{
                endAdornment:
                  listening && fieldName === name ? (
                    <InputAdornment position="start">
                      <IconButton
                        style={{
                          paddingRight: '0px',
                        }}
                        onClick={e => {
                          if (listening) {
                            setRecord(false)
                            setFieldName('')
                          } else {
                            setRecord(true)
                          }
                        }}
                      >
                        <div style={{ color: 'red' }}>
                          <Stop />
                        </div>
                      </IconButton>
                    </InputAdornment>
                  ) : (
                    <Mic
                      onClick={() => {
                        setRecord(true)
                        setFieldName(name)
                      }}
                    />
                  ),
              }}
            />
          )
        }}
        {...controllerProps}
      />
    )
  } else return null
}
