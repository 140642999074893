import { ColumnFlex, Container, EmptyState } from '@ifca-ui/core'
import { SalesContractSubheader } from 'components/General/SalesContractSubheader'
import useAppData from 'components/General/useAppData'
import { useGetNewPBdataQuery, useGetPBdataListQuery } from 'generated/graphql'
import { useSalesContractById } from './PBcomponent/PAprogressBillingBloc'
import usePBheader from './PBcomponent/usePBheader'
import { PBtypeListPro } from './Pbtest/PBtypeListPro'

interface props {
  isPA: boolean
}

const PAprogressBillingListing = ({ isPA }: props) => {
  const { rootState, subHeaderRef, rootDispatch, saleID } = useAppData()
  const { salesContract } = useSalesContractById()
  usePBheader(isPA)

  // const {
  //   data: { getNewPBdata } = { getNewPBdata: {} },
  // } = useGetNewPBdataQuery({
  //   variables: { saleId: saleID },
  // })

  const {
    data: { getPBdataList } = { getPBdataList: {} },
    refetch: refetchData,
  } = useGetPBdataListQuery({
    variables: { saleId: saleID },
  })
  return (
    <>
      <SalesContractSubheader getSalesContractById={salesContract} />
      <Container>
        {['sale_product', 'sale_subproduct', 'sale_package']?.every(
          key => getPBdataList[key]?.length === 0
        ) ? (
          <EmptyState title="No Record found" />
        ) : (
          <ColumnFlex gap="1rm">
            {['sale_product', 'sale_subproduct', 'sale_package']?.map(
              key =>
                getPBdataList[key] && (
                  <PBtypeListPro
                    isPA={isPA}
                    pbListData={getPBdataList[key]}
                    refetchData={refetchData}
                    type={''}
                  />
                )
            )}
          </ColumnFlex>
        )}
      </Container>
    </>
  )
}
export default PAprogressBillingListing
