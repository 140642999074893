import { BehaviorSubject } from 'rxjs'

let loadingPromise = new BehaviorSubject<boolean>(false)

export const setLoading = (isLoading: boolean) => {
  loadingPromise.next(isLoading)
}

export const loadingObservable = () => {
  return loadingPromise
}
