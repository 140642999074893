import { lazy } from 'react'
import { RouteObject } from 'react-router'
import { PurchaseInfoForm } from './SalesContractsForm/PurchaserInfoModule/PurchaseInfoForm'
import { PurchaseInfoDetail } from './SalesContractsForm/PurchaserInfoModule/PurchaserInfoDetail'
import PAprogressBillingListing from '../PurchaserAccounts/ProgressBilling/PAprogressBillingListing'
import PAprogressBillingDetail from '../PurchaserAccounts/ProgressBilling/PAprogressBillingDetail'
import { SRegStateProvider } from './NewSalesContractsForm/components/SRegState'

const SalesContractsSubmenu = lazy(() =>
  import('./SalesContractsSubmenuNew').then(module => ({
    default: module.SalesContractsSubmenuNew,
  }))
)
const SalesContractsSubmenuPro = lazy(() =>
  import('./SalesContractsSubmenuPro').then(module => ({
    default: module.default,
  }))
)
const SalesContractListing = lazy(() =>
  import('./SalesContractsListing').then(module => ({
    default: module.SalesContractListing,
  }))
)
const SalesContractsEditDetails = lazy(() =>
  import('./SalesContractsEditDetails/SalesContractsEditDetail').then(
    module => ({
      default: module.SalesContractsEditDetail,
    })
  )
)

const SalesContractDetailForm = lazy(() =>
  import('./SalesContractsForm/SalesContractsDetailForm').then(module => ({
    default: module.SalesContractsDetailForm,
  }))
)

const ContractPricingDetail = lazy(() =>
  import(
    './SalesContractsForm/ContractPricingModule/ContractPricingDetail'
  ).then(module => ({
    default: module.ContractPricingDetail,
  }))
)

const TestPage = lazy(() => import('./NewSalesContractsForm/testPage'))

const SalesContractRoutes: RouteObject[] = [
  {
    path: 'sales-contracts-pro',
    children: [
      {
        path: '',
        element: <SalesContractListing isPro={true} />,
      },
      {
        path: ':saleID/submenu',
        children: [
          {
            path: '',
            element: (
              <SRegStateProvider>
                <SalesContractsSubmenuPro />
              </SRegStateProvider>
            ),
          },
        ],
      },
    ],
  },
  {
    path: 'sales-contracts',
    children: [
      {
        path: '',
        element: <SalesContractListing />,
      },
      {
        path: ':saleID/submenu',
        children: [
          {
            path: '',
            element: <SalesContractsSubmenu />,
          },
          {
            path: 'contract-pricing/:unitId',
            element: <ContractPricingDetail />,
          },
          {
            path: 'purchaser-info/detail',
            element: <PurchaseInfoDetail />,
          },
          {
            path: 'purchaser-info/add',
            element: <PurchaseInfoForm mode="add" />,
          },
          {
            path: 'purchaser-info/edit',
            element: <PurchaseInfoForm mode="edit" />,
          },
          {
            path: 'progress-billing',
            children: [
              {
                path: '',
                element: <PAprogressBillingListing isPA={false} />,
              },
              {
                path: ':id/view',
                element: <PAprogressBillingDetail isPA={false} />,
              },
            ],
          },
          {
            path: 'test-page',
            element: <TestPage />,
          },
          {
            path: ':registrationDetail',
            children: [
              {
                path: '',
                element: <SalesContractsEditDetails pageMode="EditForm" />,
              },

              {
                path: ':mode',
                element: <SalesContractDetailForm pageMode="EditForm" />,
              },
            ],
          },
        ],
      },
    ],
  },
]

export default SalesContractRoutes
