export const disableLabelAutocomplete = (label, separations: number = 3) => {
  // Workaround to disable autofill, DON'T touch this if u not smart
  const partLength = Math.floor((label.length + 1) / separations)
  let i = 0
  let stringList = []
  while (i < label.length) {
    stringList.push(label.slice(i, i + partLength))
    i = Math.min(i + partLength, label.length)
  }

  return (
    <span>
      <span style={{ display: 'none' }}>autoComplete</span>
      {stringList.map(each => (
        <span key={each}>
          <span>{each}</span>
          <span style={{ display: 'none' }}>autoComplete</span>
        </span>
      ))}
    </span>
  )
}
