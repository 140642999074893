import { ColumnFlex, RowFlex, RowGrid } from '@ifca-ui/core'
import { Box, Typography } from '@mui/material'
import { CardListHeader, CardListItem } from 'components/General/CardListItem'
import TextX from 'components/General/TextX'
import TooltipX from 'components/General/TooltipX'
import { useGetAllAllocationsBySaleIdLazyQuery } from 'generated/graphql'
import { formatDate } from 'helpers/StringNumberFunction/formatDate'
import { useEffect, useState } from 'react'

interface allocationListingProps {
  mainTable: string
  saleId: string
  refId: string
  fbId?: string
}

const AllocationListingComponent = ({
  mainTable,
  saleId,
  refId,
  fbId,
}: allocationListingProps) => {
  const [allocationList, setAllocationList] = useState([])

  const [
    getAllAllocations,
    {
      loading: getAllocationBillingBySaleIdLoading,
      data: { getAllAllocationsBySaleId } = {
        getAllAllocationsBySaleId: null,
      },
    },
  ] = useGetAllAllocationsBySaleIdLazyQuery({
    onCompleted: data => {
      setAllocationList(data.getAllAllocationsBySaleId)
    },
  })

  useEffect(() => {
    getAllAllocations({
      variables: {
        mainTable: mainTable,
        saleId: saleId,
        refId: refId,
        fbId: fbId,
      },
    })
  }, [mainTable, saleId, refId])

  return (
    <>
      {allocationList.length > 0 && (
        <>
          <CardListHeader
            title={
              <RowFlex fullWidth mainAxisAlignment="flex-start">
                <Typography
                  className="text-xsTitle"
                  color="primary"
                  component="span"
                >
                  Allocation (s)
                </Typography>
              </RowFlex>
            }
          >
            {allocationList?.map((el, index) => {
              return (
                <CardListItem
                  key={index}
                  body={
                    <>
                      <ColumnFlex gap="3px" fullWidth>
                        <RowGrid gridTemplateColumns="1fr 1fr 1fr" gap="6px">
                          <TooltipX title="Document Date" placement="left">
                            <TextX
                              className="text-xsTitle"
                              data={el?.documentDate}
                              dataType="Date"
                            />
                          </TooltipX>
                          <TooltipX title="Document No" placement="left">
                            <TextX
                              className="text-xsTitle"
                              data={el?.documentNo}
                            />
                          </TooltipX>

                          <Box
                            display="flex"
                            sx={{
                              padding: '0 1em',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <TooltipX title="Allocate Amount" placement="left">
                              <TextX
                                className="text-xsTitle"
                                data={el?.allocateAmount}
                                dataType="Number"
                              />
                            </TooltipX>
                          </Box>
                        </RowGrid>
                        {el.financierName && (
                          <TextX
                            className="text-xsTitle"
                            data={`${el?.financierName}(${
                              el?.financierCode || '-'
                            })`}
                          />
                        )}
                        <RowFlex mainAxisAlignment="space-between" gap="6px">
                          <TextX
                            className="text-desc"
                            data={el?.description || '-'}
                          />
                          {el.voidDate && el.voidBy && (
                            <TextX
                              className="text-xsTitle"
                              data={`${formatDate(el?.voidDate)} (${el
                                ?.voidUserData.name})`}
                            />
                          )}
                        </RowFlex>
                      </ColumnFlex>
                    </>
                  }
                />
              )
            })}
          </CardListHeader>
        </>
      )}
    </>
  )
}

export default AllocationListingComponent
